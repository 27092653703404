import { useContext } from "react";
import { I18nContext } from "../../../../contexts/i18n.context";
import { Faq } from "get-life-storybook-ts";
import "./FAQ.scss";

const FAQ = ({ lang }: { lang?: string }) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <section className="LandingFaq">
      <div className="LandingFaq-wrapper max-w-content">
        <Faq
          headerProps={{
            title: translate("landing.FAQuestions.title", lang),
          }}
          accordion={
            [
              {
                headerText: translate(
                  "landing.FAQuestions.title.firstQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.firstText",
                  lang
                ),
                expanded: true,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: translate(
                  "landing.FAQuestions.title.secondQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.secondText",
                  lang
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: translate(
                  "landing.FAQuestions.title.thirdQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.thirdText",
                  lang
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: translate(
                  "landing.FAQuestions.title.fouthQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.fouthText",
                  lang
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: translate(
                  "landing.FAQuestions.title.fiftQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.fiftText",
                  lang
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
              {
                headerText: translate(
                  "landing.FAQuestions.title.sixthQuestion",
                  lang
                ),
                bodyContent: translate(
                  "landing.FAQuestions.text.sixthText",
                  lang
                ),
                expanded: false,
                hasDisplayItemIcon: true,
                isClickable: true,
              },
            ] as any
          }
          globalRightIconProps={{
            displayedItemIcon: "PlusIcon",
            closedItemIcon: "MinusIcon",
            color: "var(--theme-primary)",
          }}
        />
      </div>
    </section>
  );
};

export default FAQ;

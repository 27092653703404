import { ButtonDS, TrustPilotDS, useDragScroll } from "get-life-storybook-ts";
import "./ReviewCards.scss";
import {
  FiveStars,
  TrustPilotLogo,
} from "get-life-storybook-ts/lib/components/TrustPilotDS/TrustPilotDS";

const ReviewCards = ({translate, lang}: {translate: (key: string, lang?: string) => string; lang?:string}) => {
  const [ref] = useDragScroll();

  const cards = (translate: (key: string, lang?: string) => string , lang?: string) => [
    {
      Date: "1 septiembre 2022",
      Name: "Andrea Gómez",
      Review: translate("landing.review.description1", lang),
      ReviewTitle: translate("landing.review.title1", lang)
    },
    {
      Date: "20 septiembre 2023",
      Name: "Nicolás González",
      Review: translate("landing.review.description2", lang),
      ReviewTitle: translate("landing.review.title2", lang)
    },
    {
      Date: "12 de marzo 2024",
      Name: "Juan Pérez",
      Review: translate("landing.review.description3", lang),
      ReviewTitle: translate("landing.review.title3", lang)
    },
    {
      Date: "20 de febrero 2024",
      Name: "Lucía Ruiz",
      Review: translate("landing.review.description4", lang),
      ReviewTitle: translate("landing.review.title4", lang)
    },
  ];

  return (
    <section className="ReviewCards">
      <div className="ReviewCards-wrapper max-w-content">
        <div className="ReviewCards-labels">
          <h2 className="H2" dangerouslySetInnerHTML={{__html: translate("landing.review.title", lang)}}>
          </h2>
          <p className="H4">
            {translate("landing.review.subtitle", lang)}
          </p>
        </div>
        <TrustPilotDS
          CurrentRateLabel={"4.7"}
          OutOfLabel={"sobre 5"}
          className={`!w-fit mx-auto`}
          typography={"BodyL"}
          TrustpilotLink={translate("landing.header.linkTrutspilot", lang)}
          ratingFirst={true}
        />
        <div className="ReviewCards-cards" ref={ref as any}>
          {cards(translate, lang).map((card, idx) => {
            const { Date, Name, Review, ReviewTitle } = card;
            return (
              <div className="ReviewCards-card" key={`${Name}_${idx}`}>
                <TrustPilotLogo />
                <div className="flex flex-col gap-[16px] mt-[16px]">
                  <span className="H4 font-bold text-[#424242]">
                    {ReviewTitle}
                  </span>
                  <p className="BodyL font-normal text-[#555555]">{Review}</p>
                </div>
                <div className="flex flex-col gap-[4px] mt-auto">
                  <span className="BodyL font-bold text-[#555555]">{Name}</span>
                  <FiveStars />
                  <span className="BodyS font-normal text-[#686868]">
                    {Date}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <ButtonDS
          label={translate("landing.review.seeMore", lang)}
          buttonType="tertiary"
          ghost={true}
          href={translate("landing.header.linkTrutspilot", lang)}
          target="_blank"
          className="w-fit mx-auto"
        />
      </div>
    </section>
  );
};

export default ReviewCards;

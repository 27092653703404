import { get, post } from "../../core/HTTPService.service";
import {
  EmployeesListRequest,
  EmployeesListResponse,
  ListExportRequestV3,
  ListRequest,
  ListRequestV3,
  PriceResponse,
} from "./Model/Request/ListRequest.model";
import InvoiceListResponse from "./Model/Response/InvoiceListResponse.model";
import {
  ListResponse,
  ListResponseV3,
} from "./Model/Response/ListResponse.model";

export default class HttpListRepository {
  private readonly API_URL_INVOICES:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/invoices`;

  private readonly API_URL_INVOICES_LIST:
    | string
    | undefined = `${this.API_URL_INVOICES}/list/`;

  private readonly API_URL_LIST_V2:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/v2/lists`;

  private readonly API_URL_LIST_V3:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/v3/list`;

  private readonly API_URL_PRICE_LEAD:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/lead/yearly/price`;

  private readonly API_URL_EMPLOYEES:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/employees`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  private getUrlParams = (params: ListRequest): URL => {
    const apiUrl: URL = new URL(
      `${this.API_URL_LIST_V2}/${params.type}/${params.startDate}/${params.endDate}/${params.indexPage}/${params.pageSize}`
    );

    const queryParams: any = {};
    if (params.omniValue) {
      queryParams.omnivalue = params.omniValue;
    }
    if (params.ordering) {
      queryParams.orderfield = params.ordering.field;
      queryParams.order = params.ordering.direction;
    }
    apiUrl.search = new URLSearchParams(queryParams).toString();

    return apiUrl;
  };

  private getUrlParamsV3 = (params: ListRequestV3): URL => {
    const apiUrl: URL = new URL(
      `${this.API_URL_LIST_V3}/${params.startDate}/${params.endDate}/${params.indexPage}/${params.pageSize}`
    );

    const queryParams: any = {};
    if (params.omniValue) {
      queryParams.omnisearch = params.omniValue;
    }

    if (params.stage) {
      queryParams.stage = params.stage;
    }

    if (params.documentStatus) {
      queryParams.documentStatus = params.documentStatus;
    }

    apiUrl.search = new URLSearchParams(queryParams).toString();

    return apiUrl;
  };

  private getUrlParamsV3Export = (params: ListExportRequestV3): URL => {
    const apiUrl: URL = new URL(`${this.API_URL_LIST_V3}/generate-export`);

    const queryParams: any = {};
    if (params.omniValue) {
      queryParams.omnisearch = params.omniValue;
    }

    if (params.stage) {
      queryParams.stage = params.stage;
    }

    if (params.documentStatus) {
      queryParams.documentStatus = params.documentStatus;
    }

    apiUrl.search = new URLSearchParams(queryParams).toString();

    return apiUrl;
  };

  private getUrlParamsEmployeeList = (params: EmployeesListRequest): URL => {
    const apiUrl: URL = new URL(
      `${this.API_URL_EMPLOYEES}/all/${params.indexPage}/${params.pageSize}`
    );

    const queryParams: any = {};
    if (params.omniValue) {
      queryParams.omnisearch = params.omniValue;
    }

    apiUrl.search = new URLSearchParams(queryParams).toString();

    return apiUrl;
  };

  getListByType = async (data: ListRequest): Promise<ListResponse> => {
    let listData: ListResponse | null = null;
    const url: any = this.getUrlParams(data);
    try {
      const response: ListResponse = await get(url, this.header);
      listData = response;
    } catch (e) {
      throw e;
    }

    return listData;
  };

  getInvoiceList = async (): Promise<InvoiceListResponse> => {
    let data: InvoiceListResponse | null = null;
    try {
      const response: InvoiceListResponse = await get(
        `${this.API_URL_INVOICES_LIST}`,
        this.header
      );
      data = response;
    } catch (error) {
      throw error;
    }
    return data;
  };

  getDocumentInvoicesDetail = async (docId: string) => {
    let result;
    try {
      const response = await get(
        `${this.API_URL_INVOICES}/detail/${docId}`,
        this.header
      );
      result = response;
    } catch (error) {
      throw error;
    }
    return result;
  };

  getProjectList = async (data: ListRequestV3): Promise<ListResponseV3> => {
    let listData: ListResponseV3 | null = null;
    const url: any = this.getUrlParamsV3(data);
    try {
      const response: ListResponseV3 = await get(url, this.header, data.signal);
      listData = response;
    } catch (e) {
      throw e;
    }

    return listData;
  };

  getPriceByLead = async (
    leadId: string,
    signal?: AbortSignal
  ): Promise<PriceResponse> => {
    let price: PriceResponse | null = null;
    try {
      const response: PriceResponse = await get(
        `${this.API_URL_PRICE_LEAD}/${leadId}`,
        this.header,
        signal
      );

      price = response;
    } catch (e) {
      throw e;
    }

    return price;
  };

  getEmployeesList = async (
    data: EmployeesListRequest
  ): Promise<EmployeesListResponse> => {
    let employees: EmployeesListResponse | null = null;
    const url: any = this.getUrlParamsEmployeeList(data);
    try {
      const response: EmployeesListResponse = await get(
        url,
        this.header,
        data.signal
      );

      employees = response;
    } catch (e) {
      throw e;
    }

    return employees;
  };

  postListGenerateExport = async (
    data: ListExportRequestV3
  ): Promise<ListResponseV3> => {
    let listData: ListResponseV3 | null = null;
    const url: any = this.getUrlParamsV3Export(data);
    try {
      const response: ListResponseV3 = await post(url, {}, this.header);
      listData = response;
    } catch (e) {
      throw e;
    }

    return listData;
  };
}

import { GlobalIcon, IconNameT } from "get-life-storybook-ts";
import "./InsuranceSteps.scss";

const InsuranceSteps = ({
  translate,
  lang,
}: {
  translate: (key: string, lang?: string) => string;
  lang?: string;
}) => {
  const steps = [
    {
      icon: "CalculatorIcon" as IconNameT,
      title: translate("landing.insuranceSteps.step1Title", lang),
      description: translate("landing.insuranceSteps.step1Description", lang),
    },
    {
      icon: "OnlineIcon" as IconNameT,
      title: translate("landing.insuranceSteps.step2Title", lang),
      description: translate("landing.insuranceSteps.step2Description", lang),
    },
    {
      icon: "SimpleShieldIcon" as IconNameT,
      title: translate("landing.insuranceSteps.step3Title", lang),
      description: translate("landing.insuranceSteps.step3Description", lang),
    },
  ];

  return (
    <section className="InsuranceSteps">
      <div className="InsuranceSteps-wrapper max-w-content">
        <h2 className="InsuranceSteps-mainTitle H2">
          {translate("landing.insuranceSteps.title", lang)}
        </h2>
        <div className="InsuranceSteps-steps">
          {steps.map((s, idx) => {
            const { icon, title, description } = s;
            const isLast = idx + 1 === steps.length;
            return (
              <div className="InsuranceSteps-step" key={`${title}_${idx}`}>
                {!isLast ? (
                  <picture className="InsuranceSteps-arrow">
                    <source
                      srcSet="https://storage.googleapis.com/getlife-bucket-1/public/image/vertical-arrow-vector.png"
                      media="(max-width: 1023px)"
                    />
                    <img
                      src="https://storage.googleapis.com/getlife-bucket-1/public/image/arrow-vector.png"
                      alt=""
                    />
                  </picture>
                ) : null}
                <span className="InsuranceSteps-icon">
                  <GlobalIcon
                    iconName={icon}
                    size="M"
                    color="var(--theme-primary)"
                  />
                </span>
                <span className="InsuranceSteps-title H4">{title}</span>
                <p
                  className="InsuranceSteps-description BodyL"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default InsuranceSteps;

import { GlobalIcon } from "get-life-storybook-ts";
import "./LandingBanner.scss";

const LandingBanner = ({
  translate,
  lang,
}: {
  translate: (key: string, lang?: string) => string;
  lang?: string;
}) => {
  return (
    <section className="LandingBanner">
      <div className="LandingBanner-wrapper max-w-content">
        <div className="LandingBanner-section">
          <div className="LandingBanner-content">
            <div>
              <span className="LandingBanner-subtitle BodyS">
                {translate("landing.banner.title", lang)}
              </span>
              <h2 className="LandingBanner-title H1">
                {translate("landing.banner.subtitle", lang)}
              </h2>
            </div>
            <p className="BodyL">
              {translate("landing.banner.description", lang)}
            </p>
            <p className="BodyL font-bold">
              {translate("landing.banner.header", lang)}
            </p>
            <ul className="LandingBanner-list BodyL font-bold">
              <li>
                <GlobalIcon
                  iconName="FilledCheckIcon"
                  size="XS"
                  color={"var(--theme-primary)"}
                />
                {translate("landing.banner.list1", lang)}
              </li>
              <li>
                <GlobalIcon
                  iconName="FilledCheckIcon"
                  size="XS"
                  color={"var(--theme-primary)"}
                />
                {translate("landing.banner.list2", lang)}
              </li>
              <li>
                <GlobalIcon
                  iconName="FilledCheckIcon"
                  size="XS"
                  color={"var(--theme-primary)"}
                />
                {translate("landing.banner.list3", lang)}
              </li>
              <li>
                <GlobalIcon
                  iconName="FilledCheckIcon"
                  size="XS"
                  color={"var(--theme-primary)"}
                />
                {translate("landing.banner.list4", lang)}
              </li>
            </ul>
          </div>
          <picture className="LandingBanner-banner">
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/familytogether2.jpeg"
              alt=""
            />
          </picture>
        </div>
        <div className="LandingBanner-section items-center">
          <picture className="LandingBanner-banner h-[324px]">
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/familytogetheripa.jpeg"
              alt=""
            />
          </picture>
          <div className="LandingBanner-content">
            <div>
              <span className="LandingBanner-subtitle BodyS">
                {translate("landing.banner.ipaTitle", lang)}
              </span>
              <h2 className="LandingBanner-title H1">
                {translate("landing.banner.ipaSubtitle", lang)}
              </h2>
            </div>
            <p className="BodyL">
              {translate("landing.banner.ipaDescription", lang)}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingBanner;

import { ButtonDS, CustomModal, InputDS } from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import { useContext, useEffect, useRef, useState } from "react";

interface CustomDateModalI {
  open: boolean;
  setOpen: (value: boolean) => void;
  submit: ({ start, end }: { start: string; end: string }) => void;
}

const CustomDateModal = ({ open, setOpen, submit }: CustomDateModalI) => {
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [error, setError] = useState<{
    start?: string;
    end?: string;
  }>({});

  const modalRef = useRef();
  const {
    state: { translate },
  } = useContext(I18nContext);

  const validateFields = () => {
    let _error: { start?: string; end?: string } = {};

    if (!selectedStartDate) {
      _error.start = translate("custom.date.modal.startDateMandatory");
    }
    if (!selectedEndDate) {
      _error.end = translate("custom.date.modal.endDateMandatory");
    }
    if (selectedStartDate && selectedStartDate < "2020-01-01") {
      _error.start = translate("custom.date.modal.dateBeforeYear");
    }
    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedStartDate > selectedEndDate
    ) {
      _error.start = translate("custom.date.modal.startDateLesserThanEndDate");
    }
    const currentDate = new Date().toISOString().split("T")[0];
    if (selectedEndDate && selectedEndDate > currentDate) {
      _error.end = translate("custom.date.modal.endDateLesserThanCurrentDate");
    }

    return _error;
  };

  const submitData = () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      return setError(errors);
    } else {
      submit({ start: selectedStartDate, end: selectedEndDate });
      onCloseCallback();
      // @ts-ignore
      modalRef?.current?.handleShowPortal();
    }
  };

  const onCloseCallback = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open === true) {
      // @ts-ignore
      modalRef?.current?.handleShowPortal();
    }
  }, [open]);

  return (
    <CustomModal
      fitContent
      ref={modalRef}
      ModalTitle={
        <div className="text-[#555] BodyM font-medium">
          {translate("custom.date.modal.custom")}
        </div>
      }
      ModalBody={
        <div className="flex flex-col gap-[24px]">
          <InputDS
            label={translate("custom.date.modal.from")}
            type="date"
            icon="CalendarEventLineIcon"
            value={selectedStartDate}
            onChange={(e) => setSelectedStartDate(e.target.value)}
            error={error.start}
          />
          <InputDS
            label={translate("custom.date.modal.to")}
            type="date"
            icon="CalendarEventLineIcon"
            value={selectedEndDate}
            onChange={(e) => setSelectedEndDate(e.target.value)}
            error={error.end}
          />
          <ButtonDS
            label={translate("custom.date.modal.apply")}
            rightIcon="ArrowRightIcon"
            onClick={() => submitData()}
            className="w-fit ml-auto"
          />
        </div>
      }
      handleCloseCallback={onCloseCallback}
    />
  );
};

export default CustomDateModal;

import { Chip } from "get-life-storybook-ts";
import { Status } from "../constants/status";
import { ListProjectsV3 } from "../../../../api/request/Lists/Model/Response/ListResponse.model";

const statusBiometry = "ops_review, it_review , insurer_review , docs_pending";

export const renderstatus = (
  translate: (key: string) => string,
  project: ListProjectsV3,
  isBottom: boolean = false
) => {
  const PENDING = project.documentStatus === "PENDING";
  const IN_REVIEW = project.documentStatus === "IN_REVIEW";
  const ERROR = project.documentStatus === "ERRORED";

  return Status(translate).map((stage) => {
    const chipsArray = [];
    if (stage.value.includes(project.status)) {
      if (project.coverage === "eg") {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"WiderUmbrellaIcon"}
            tooltip={translate("projects.tooltip.eg")}
            className="p-[4px] border border-[#5D5AE9]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (project.hasMaxCapital) {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"CashIcon"}
            tooltip={translate("projects.tooltip.maxCapital")}
            className="p-[4px]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (project.hasTelesubscription) {
        chipsArray.push(
          <Chip
            type={"default"}
            icon={"MedicalIcon"}
            tooltip={translate("projects.tooltip.telesubscription")}
            className="p-[4px]"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      if (statusBiometry.includes(project.status)) {
        chipsArray.push(
          <Chip
            text={
              PENDING
                ? translate("projects.stage.biometryPending")
                : IN_REVIEW
                ? translate("projects.stage.biometryReview")
                : ERROR
                ? translate("projects.stage.biometryError")
                : translate("projects.stage.biometryValidated")
            }
            type={
              PENDING
                ? "warning"
                : IN_REVIEW
                ? "info"
                : ERROR
                ? "destructive"
                : "success"
            }
            icon={
              PENDING
                ? "FileAlertIcon"
                : IN_REVIEW
                ? "FileSearchIcon"
                : ERROR
                ? "FileXIcon"
                : "ShieldCheckIcon"
            }
            tooltip={translate(
              `projects.tooltip.${project.documentStatus.toLowerCase()}`
            )}
            className="py-[4px] whitespace-nowrap"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      } else {
        chipsArray.push(
          <Chip
            text={stage.title}
            type={stage.chipType}
            icon={stage.chipIcon}
            tooltip={stage.tooltip}
            className="py-[4px] whitespace-nowrap"
            tooltipDirection={isBottom ? "top center" : "bottom center"}
          />
        );
      }

      return chipsArray;
    }
  });
};

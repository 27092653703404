import { get } from "../../core/HTTPService.service";
import CapitalLimitsResponse from "./Model/Response/CapitalLimitsResponse.model";

export default class HttpTarifierRepository {
  private readonly API_URL_TARIFIER:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMERS_APP_URL}lead/capital/tarifier`;

  private readonly header: { Authorization: string } | undefined;

  constructor(token?: string) {
    this.header = token ? { Authorization: `Bearer ${token}` } : undefined;
  }

  getCapitalLimits = async (date: string): Promise<CapitalLimitsResponse> => {
    let capital: CapitalLimitsResponse;
    try {
      const response: CapitalLimitsResponse = await get(
        `${this.API_URL_TARIFIER}/extendedBoundaries/${date}`
      );
      capital = response;
    } catch (error) {
      throw error;
    }
    return capital;
  };
}

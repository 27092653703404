import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormCalcPrice from "../components/formCalcPrice/formCalcPrice";
import "./dashboardMain.scss";
import "../components/buttons/css/buttonPrimary.scss";
import Form from "../components/Form/Form";
import { ContextGetlife } from "../../contexts/ContextGetlife";
import HttpLeadRepository from "../../api/request/Lead/lead.service";
import { I18nContext } from "../../contexts/i18n.context";
import FormCalcPriceFR from "../components/formCalcPrice/formCalcPriceFR";
import {
  ButtonDS,
  GlobalIcon,
  IconButton,
  InputDS,
} from "get-life-storybook-ts";
import FormCalcPriceIT from "../components/formCalcPrice/formCalcPriceIT";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import DownloadableFilesModalDS from "../components/DownloadableFiles/DownloadableFilesModalDS";
import apiLeadSendDocument from "../../api/request/apiLeadSendDocument";
import { formatPriceWithoutCurrency } from "../../utils/numberFormat";
import CardsBroker from "../CardsBroker/CardsBroker";

export default function DashboardMain(props) {
  const {
    broker,
    buttonCalculate,
    capital,
    capitalLimits,
    error,
    setError,
    errorMessage,
    isActiveRelatedEmail,
    handleInsurancePrice,
    handleSubmitOrganic,
    initialValues,
    insurancePrice,
    setBirthDate,
    setCapital,
    setInsuranceType,
    setIsActiveRelatedEmail,
    setNotOwner,
    setShowAlerts,
    setButtonCalculate,
    setShowResults,
    showAlerts,
    showResults,
    traits,
    workingProfesionList,
    downloadablePdfs,
    submittedValues,
    updatePrice,
    fetchDownloadables,
    setLeadId,
    emailToSend,
  } = props;

  const [downloadableModal, setDownloadableModal] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [validateCoupon, setValidateCoupon] = useState("");
  const [promoLoading, setPromoLoading] = useState(false);
  const [displayPromo, setDisplayPromo] = useState(false);

  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

  const { leadId, setShowNotification, token } = useContext(ContextGetlife);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const BROKER_ES = broker?.brokerageId === 1;
  const isSelectraBoker = broker?.brokerageId === 666;
  const isGetlifeBroker = BROKER_ES;

  const {
    state: { translate },
  } = useContext(I18nContext);
  const leadRepository = new HttpLeadRepository(token);
  const navigate = useNavigate();
  const navigateToQuestions = async (resetForm) => {
    if (isSelectraBoker) {
      window.open(
        "https://meetings-eu1.hubspot.com/life5/rdv-life5-assurance-deces",
        "_blank"
      );
      handleResetForm(resetForm);
    } else {
      try {
        //await leadRepository.questionsByLeadId(leadId);
        handleInsurancePrice(false);
        navigate(`/questions`);
        handleResetForm(resetForm);
      } catch (error) {
        setShowNotification({
          message: translate("errors.global.notUserActivated"),
          status: true,
          type: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          link: (
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href="mailto:broker@life5.com"
            >
              {" "}
              broker@life5.com
            </a>
          ),
        });
      }
    }
  };

  const handleChangeBirthDate = (values) => {
    if (!!values.date && values.date.split("-")[0] > 1000) {
      setBirthDate(values.date);
    }
  };

  const handleMoreCapital = (values, setValue) => {
    let newValue = values.capital + capitalLimits.step;
    if (newValue <= capitalLimits.maxCapital) {
      setValue("capital", newValue);
      setCapital(newValue);
    } else {
      setValue("capital", capitalLimits.maxCapital);
      setCapital(capitalLimits.maxCapital);
    }
  };
  const handleLessCapital = (values, setValue) => {
    let newValue = values.capital - capitalLimits.step;

    if (newValue >= capitalLimits.minCapital) {
      setValue("capital", newValue);
      setCapital(newValue);
    } else {
      setValue("capital", capitalLimits.minCapital);
      setCapital(capitalLimits.minCapital);
    }
  };
  const handleChangeCapital = (data, setValue) => {
    setValue("capital", data);
    setCapital(data);
  };

  const handleResetForm = (resetFrom) => {
    resetFrom();
    setCapital("");
    setNotOwner(false);
    setShowResults(false);
    setButtonCalculate("calculate");
    setShowAlerts({
      maxCapital: false,
      disability: false,
      recalcMaxCapital: false,
      recalcDisability: false,
    });
  };

  const setValuesCapital = (values, setValue) => {
    if (values.capital !== capital) {
      setValue("capital", capital);
    }
  };

  const fetchPromo = async (code) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.applyCoupon({
        promotionalCode: code,
      });
      if (response.promotionalCodeHasApplied) {
        setValidateCoupon("validate");
        updatePrice(leadId, token);
        fetchDownloadables(leadId);
      }
      setPromoLoading(false);
    } catch (error) {
      setValidateCoupon("invalidate");
      setPromoLoading(false);
    }
  };

  const removePromo = async (code) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      if (response.removed) {
        setValidateCoupon("");
        updatePrice(leadId, token);
        fetchDownloadables(leadId);
        setPromoCode("");
      }
      setPromoLoading(false);
    } catch (error) {
      setPromoLoading(false);
    }
  };

  const sendEmailDocument = async (email, text, template) => {
    const type = "general-a-exclusions";
    const resource = null;
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        template.includes(",") ? template.split(",") : [template]
      );
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  return (
    <>
      <div className="newDashboard-grid">
        <div className="newDashboard-calculator">
          <p className="BodyL font-bold flex flex-row gap-[16px] text-[#424242]">
            <GlobalIcon iconName="HeartIcon" size="XS" color="currentColor" />
            {translate("dashboard.calculator.mainTitle")}
          </p>
          <Form initialValues={{ ...initialValues }}>
            {({ values, setValue, resetForm }) => {
              handleChangeBirthDate(values);
              setValuesCapital(values, setValue);
              const resetAllForm = () => {
                setLeadId("");
                setPromoCode("");
                setValidateCoupon("");
                setPromoLoading(false);
                setShowResults(false);
                resetForm();
              };

              const validateBirthdate = async (value) => {
                await leadRepository
                  .getValidationActuarialAge(values["date"])
                  .then()
                  .catch((e) => {
                    setError([
                      ...error,
                      {
                        field: "date",
                        message: translate("errors.validate.date"),
                      },
                    ]);
                  });
              };

              return (
                <>
                  <div className="newDashboard-tariffifier">
                    <div className="BodyM font-bold flex flex-row justify-between items-center text-[#424242] mb-[24px]">
                      {translate("dashboard.calculator.tariffifier")}
                      <ButtonDS
                        label={translate("dashboard.calculator.restart")}
                        buttonType="secondary"
                        leftIcon="RotateIcon"
                        size="32"
                        onClick={resetAllForm}
                      />
                    </div>
                    {DOMAIN === "es" && (
                      <FormCalcPrice
                        validateBirthdate={validateBirthdate}
                        buttonCalculate={buttonCalculate}
                        capital={capital}
                        capitalLimits={capitalLimits}
                        death={traits.DEATH}
                        disability={traits.DISABILITY}
                        error={error}
                        setError={setError}
                        errorMessage={errorMessage}
                        isActiveRelatedEmail={isActiveRelatedEmail}
                        isGetlifeBroker={isGetlifeBroker}
                        translate={translate}
                        handleSubmitOrganic={(form, setValue) => {
                          setPromoCode("");
                          setValidateCoupon("");
                          setPromoLoading(false);
                          handleSubmitOrganic(form, setValue);
                        }}
                        setCapital={handleChangeCapital}
                        setInsuranceType={setInsuranceType}
                        setIsActiveRelatedEmail={setIsActiveRelatedEmail}
                        setValue={setValue}
                        showAlerts={showAlerts}
                        values={values}
                        showResults={showResults}
                      />
                    )}
                    {DOMAIN === "it" && (
                      <FormCalcPriceIT
                        validateBirthdate={validateBirthdate}
                        buttonCalculate={buttonCalculate}
                        capital={capital}
                        capitalLimits={capitalLimits}
                        death={traits.DEATH}
                        disability={traits.DISABILITY}
                        error={error}
                        setError={setError}
                        errorMessage={errorMessage}
                        translate={translate}
                        handleSubmitOrganic={(form, setValue) => {
                          setPromoCode("");
                          setValidateCoupon("");
                          setPromoLoading(false);
                          handleSubmitOrganic(form, setValue);
                        }}
                        setCapital={handleChangeCapital}
                        setValue={setValue}
                        showAlerts={showAlerts}
                        values={values}
                        showResults={showResults}
                      />
                    )}
                    {DOMAIN === "fr" && (
                      <FormCalcPriceFR
                        validateBirthdate={validateBirthdate}
                        buttonCalculate={buttonCalculate}
                        capital={capital}
                        capitalLimits={capitalLimits}
                        death={traits.DEATH}
                        disability={traits.DISABILITY}
                        error={error}
                        setError={setError}
                        errorMessage={errorMessage}
                        isActiveRelatedEmail={isActiveRelatedEmail}
                        isGetlifeBroker={isGetlifeBroker}
                        isSelectraBoker={isSelectraBoker}
                        translate={translate}
                        handleSubmitOrganic={handleSubmitOrganic}
                        lessCapital={handleLessCapital}
                        moreCapital={handleMoreCapital}
                        setCapital={handleChangeCapital}
                        setIsActiveRelatedEmail={setIsActiveRelatedEmail}
                        workingProfesionList={workingProfesionList}
                        setValue={setValue}
                        showAlerts={showAlerts}
                        values={values}
                        showResults={showResults}
                      />
                    )}
                  </div>
                  {showResults ? (
                    <div className="newDashboard-tariffifier" id="result">
                      <div className="BodyM font-bold flex flex-row justify-between items-center text-[#424242] mb-[24px]">
                        {translate("dashboard.calculator.tariffifierClient")}
                        <ButtonDS
                          label={translate("dashboard.calculator.restart")}
                          buttonType="secondary"
                          leftIcon="RotateIcon"
                          size="32"
                          onClick={resetAllForm}
                        />
                      </div>
                      <div className="newDashboard-tariffifierGrid">
                        <div className="newDashboard-tariffifierGridColumn newDashboard-tariffifierGuarantees">
                          <span className="BodyL text-[#555555]">
                            {translate(
                              "dashboard.calculator.includedGuarantees"
                            )}
                          </span>
                          <div className="newDashboard-guaranteeResult bodyM">
                            <GlobalIcon
                              iconName="Cross2Icon"
                              size="XS"
                              color="var(--primary-color)"
                            />
                            <span>
                              {translate("dashboard.calculator.decease")}
                            </span>
                            {submittedValues?.insuranceType?.value ===
                              "premium" ? (
                              <>
                                <span>+</span>
                                <span className="flex flex-row gap-[8px]">
                                  <GlobalIcon
                                    iconName="DisabledIcon"
                                    size="XS"
                                    color="var(--primary-color)"
                                  />
                                  <span>
                                    {translate("dashboard.calculator.ipa")}
                                  </span>
                                </span>
                              </>
                            ) : null}
                          </div>
                          <div className="newDashboard-guaranteeDivider" />
                          <span className="BodyL text-[#555555]">
                            {translate("dashboard.calculator.estimatedPrice")}
                          </span>
                          <div className="newDashboard-estimatedPrice BodyS">
                            <div>
                              <span className="mb-[4px]">
                                {translate("dashboard.calculator.monthlyFee")}
                              </span>
                              {insurancePrice?.isPromoted ? (
                                <span
                                  className="newDashboard-promoPriceLabel BodyS"
                                  dangerouslySetInnerHTML={{
                                    __html: insurancePrice?.beforePromoText,
                                  }}
                                />
                              ) : null}
                              {
                                insurancePrice?.monthlyPrice !== 0 ? (
                                  <span className="H3">
                                    {formatPriceWithoutCurrency(
                                      insurancePrice?.isPromoted
                                        ? insurancePrice?.monthlyPromotedPrice
                                        : insurancePrice?.monthlyPrice
                                    )}
                                    <span className="BodyS align-top">
                                      {translate("dashboard.calculator.monthly")}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="H3">{translate("table.capital.notAvailable")}</span>
                                )
                              }

                              {insurancePrice?.isPromoted ? (
                                <span
                                  className="newDashboard-promoPriceLabel BodyS"
                                  dangerouslySetInnerHTML={{
                                    __html: insurancePrice?.afterPromoText,
                                  }}
                                />
                              ) : null}
                            </div>
                            {
                              insurancePrice?.monthlyPrice !== 0 && (
                                <div>
                                  <span className="mb-[4px]">
                                    {translate("dashboard.calculator.yearlyFee")}
                                  </span>
                                  {insurancePrice?.isPromoted ? (
                                    <span className="BodyS text-[#7B7B7B]">
                                      {translate("dashboard.calculator.withPromo")}
                                    </span>
                                  ) : null}
                                  <span className="H3">
                                    {formatPriceWithoutCurrency(
                                      insurancePrice?.isPromoted
                                        ? insurancePrice?.annualPromotedPrice
                                        : insurancePrice?.annualPrice
                                    )}
                                    <span className="BodyS align-top">
                                      {translate("dashboard.calculator.yearly")}
                                    </span>
                                  </span>
                                </div>)}
                          </div>
                        </div>
                        <div className="newDashboard-tariffifierGridColumn">
                          <div className="newDashboard-coupons BodyL">
                            <span className="newDashboard-couponTitle">
                              {translate("dashboard.calculator.addCoupon")}
                            </span>
                            <IconButton
                              icon={displayPromo ? "MinusIcon" : "PlusIcon"}
                              buttonType="secondary"
                              size="32"
                              onClick={() => setDisplayPromo(!displayPromo)}
                            />
                          </div>
                          <div
                            className={`newDashboard-addCoupon ${displayPromo ? "open" : "closed"
                              }`}
                          >
                            <InputDS
                              label={translate("dashboard.calculator.coupon")}
                              name="promoCode"
                              icon="Tag2Icon"
                              placeholder={translate(
                                "dashboard.calculator.couponPlaceholder"
                              )}
                              onChange={(e) => setPromoCode(e.target.value)}
                              disabled={
                                validateCoupon === "validate" ? true : false
                              }
                              error={
                                validateCoupon === "invalidate"
                                  ? translate(
                                    "dashboard.calculator.promoInvalidate"
                                  )
                                  : null
                              }
                            />
                            {validateCoupon === "validate" ? (
                              <button
                                className={"newDashboard-removeCoupon BodyS"}
                                onClick={() => removePromo(promoCode)}
                              >
                                <GlobalIcon
                                  iconName="TrashIcon"
                                  size="XXS"
                                  color="currentColor"
                                />
                                {translate("dashboard.calculator.removePromo")}
                              </button>
                            ) : null}
                            <ButtonDS
                              label={
                                validateCoupon === "validate"
                                  ? translate(
                                    "dashboard.calculator.couponApplied"
                                  )
                                  : translate(
                                    "dashboard.calculator.applyCoupon"
                                  )
                              }
                              leftIcon="Tag2Icon"
                              buttonType="tertiary"
                              size="32"
                              className="newDashboard-addCouponButton w-fit"
                              onClick={() => fetchPromo(promoCode)}
                              disabled={
                                promoLoading || validateCoupon === "validate"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {insurancePrice.maxCapital && (
                        <div className="pt-2 text-dark-gray-100">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "edit.projects.maxCapitalHelper"
                              ),
                            }}
                          />
                        </div>
                      )}
                      <div className="newDashboard-resultsCTA">
                        <ButtonDS
                          leftIcon="FileDownload"
                          buttonType="secondary"
                          label={translate(
                            "dashboard.resume.downloadableFiles"
                          )}
                          onClick={() => setDownloadableModal(true)}
                        />
                        <ButtonDS
                          disabled={insurancePrice.maxCapital}
                          rightIcon="ArrowRightIcon"
                          label={translate("dashboard.button.contract")}
                          onClick={() => navigateToQuestions(resetForm)}
                        />
                      </div>
                    </div>
                  ) : null}
                  <DownloadableFilesModalDS
                    open={downloadableModal}
                    setOpen={setDownloadableModal}
                    downloadablePdfs={downloadablePdfs}
                    emailToSend={emailToSend}
                    sendEmail={sendEmailDocument}
                  />
                </>
              );
            }}
          </Form>
        </div>
        <CardsBroker />
      </div>
    </>
  );
}

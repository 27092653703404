import { ButtonDS } from "get-life-storybook-ts";
import "./LandingContact.scss";

const LandingContact = ({translate, lang}: {translate: (key: string, lang?: string) => string; lang?:string}) => {
  return (
    <section className="LandingContact">
      <div className="LandingContact-wrapper max-w-content">
        <h2 className="H2">{translate("landing.contact.title", lang)}</h2>
        <p className="BodyL" dangerouslySetInnerHTML={{
              __html: translate("landing.contact.subtitle", lang),
            }}>
         
        </p>
        <ButtonDS
          label={translate("landing.contact.button", lang)}
          rightIcon="ArrowRightIcon"
          buttonType="secondary"
          className="mx-auto"
          href="#landing-contact"
        />
      </div>
    </section>
  );
};

export default LandingContact;

export const EditButton =
  "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,telesubscription,upsell_pending";

export const IdentificationButton =
  "ops_review,it_review,insurer_review,docs_pending,complete";

export const DisableRecoverButton =
  "product_questions_pending,questions_pending,prestack_pending,personaldata_pending,upsell_pending,paymentmethodpending_pending,payment_pending,payment_validation_pending,nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending";

export const DisableViewButtonAdmin =
  "paymentmethodpending_pending,payment_pending,payment_validation_pending,nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending,canceled,claim";

export const DisableViewButton = DisableViewButtonAdmin + ",rejected";

export const enableEmailsSelectra = [
  "stevie.piolat+selectra@life5.com",
  "chary.chira+selectra@life5.com",
  "isabel.perez+selectra@life5.com",
  "florence.ribes+selectra@life5.com",
  "christophe.moreira+selectra@life5.com",
  "emilien.mercier+selectra@life5.com",
  "laurent.legac+selectra@life5.com",
  "laurent.legac+selectra@life5.com",
  "gregor+selectra@life5.com",
];

export const EnableOtpButton =
  "paymentmethodpending_pending,payment_pending,payment_validation_pending,nationality_pending,address_pending,beneficiaries_pending,beneficiariestypes_pending,physicalperson_pending,bankingentity_pending,legalperson_pending,idcard_pending,legalnote_pending,signature_pending,complete,ops_review,it_review,insurer_review,claim,docs_pending,csv_pending,validation_pending";

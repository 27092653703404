import React from "react";
import { GlobalIcon, TrustPilotDS } from "get-life-storybook-ts";
import "./LandingCalculator.scss";
import CalculatorModuleExample from "./CalculatorModule/CalculatorExample";

const LandingCalculator = ({ brokerId, data, translate, lang }: any) => {
  return (
    <div
      className="LandingCalculator"
      style={{ backgroundColor: "#FFFFFF" }}
      id="landing-calculator"
    >
      <div className="LandingCalculator-wrapper max-w-content">
        <div className="LandingCalculator-calculatorWrapper">
          <div className="mb-[24px]">
            <h2 className="H1">
              {translate("landing.tittle.h1", lang)}
              <span className="H1 font-MW">
                {translate("landing.tittle.h2", lang)}
              </span>
            </h2>
            <p className="LandingCalculator-subtitle H4">
              {translate("landing.tittle.text", lang)}
            </p>
          </div>
          <CalculatorModuleExample
            data={data}
            brokerId={brokerId}
            translate={translate}
            lang={lang}
          />
          <TrustPilotDS
            CurrentRateLabel={"4.8"}
            OutOfLabel={"de 5"}
            className="text-center mt-[32px] w-full"
            TrustpilotLink={translate("landing.header.linkTrutspilot", lang)}
          />
          <GlobalIcon
            iconName="LoopingArrow"
            className="LandingCalculator-loopingArrow"
            color="var(--theme-primary)"
          />
        </div>
        <div
          className={`LandingCalculator-imageHeight`}
          style={
            {
              "--heroCalculatorImageHeight": "520px",
              "--heroCalculatorImageOffset": "0px",
            } as React.CSSProperties
          }
        >
          <img
            className={"LandingCalculator-image"}
            src={
              "https://storage.googleapis.com/getlife-bucket-1/public/image/life5/heroCalculatorImagedemo.jpg"
            }
            alt={""}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCalculator;
